@charset "UTF-8";
:root {
  --black: hsl(0, 0%, 6%);
  --orange: hsl(32, 93%, 55%);
  --gray: hsl(0, 0%, 20%);
  --white: hsl(0, 0%, 100%);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Open Sans", Arial, sans-serif;
  background-color: #ececec;
  color: var(--black);
  font-size: 16px;
  height: 100%;
  line-height: 1.5;
  position: relative;
}
body h1, body h2, body h3, body h4, body h5, body h6 {
  font-family: var("Poppins", Arial, sans-serif);
  color: var(--black);
  font-weight: 700;
}
body h1 {
  font-size: 2rem;
  line-height: 1.2;
}
body h2 {
  font-size: 1.5rem;
}
body h3 {
  font-size: 1.2rem;
  position: relative;
  display: inline-block;
}
body h4 {
  font-size: 1rem;
}
body h5 {
  font-weight: 300;
  font-size: 22px;
}
body a {
  transition: 0.2s;
}
body a:hover {
  color: var(--black);
}
body .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
body .container {
  max-width: 1140px;
  margin: 0 auto;
}
body .pagination {
  display: flex;
  justify-content: center;
  width: 100%;
}
body .pagination li {
  margin: 0 4px;
}
body .pagination li a {
  background-color: #969696;
  color: var(--black);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}
body .pagination li span {
  background-color: rgba(150, 150, 150, 0.2);
  color: var(--black);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}
body .pagination li.active span {
  background-color: var(--orange);
  color: var(--white);
}
body .loading {
  position: relative;
  text-align: center;
  height: 265px;
}
body .loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

header {
  background: rgba(50, 50, 50, 0.9);
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.22);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.22);
  position: fixed;
  width: 100%;
  z-index: 500;
  top: 0;
}
header .main_menu {
  color: #fff;
  width: 100%;
  z-index: 500;
  display: flex;
  height: 60px;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
  align-items: center;
}
header .main_menu .container_logo a {
  display: flex;
  align-items: center;
}
header .main_menu .container_menu {
  /*ul#nav{
  	li{
  		display: inline;
  		margin-left: 20px;
  		a{
  			color: $color_blanco;
  			font-size: 14px;
  			position: relative;
  			text-decoration: none;
  			&:hover, &:focus {
  				color: #ccc;
  			}
  		}
  	}
  }*/
}
header .main_menu .container_menu .menu-square {
  height: 60px;
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  position: absolute;
  top: 0;
  right: 0;
}
header .main_menu .container_menu .menu-square .menu-background {
  height: 100%;
  background-color: var(--orange);
  color: var(--gray);
  display: inline-block;
  width: auto;
  font-size: 40px;
  padding: 0 21px;
}
header .main_menu .container_menu .menu-square .menu-background .icon_menu {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  display: block;
}
header.community-manager #community-manager-linker {
  color: #f79420 !important;
}
header.paginas-web #pagina-web {
  color: #f79420 !important;
}
header.publicidad #publicidad {
  color: #f79420 !important;
}

form input[type=text], form input[type=tel], form input[type=email] {
  height: 50px;
  width: 100%;
  border: none;
  padding: 10px 30px;
  font-size: 16px;
  line-height: 20px;
  color: inherit;
  border-radius: 25px;
  font-weight: bold;
  outline: none;
  margin-bottom: 20px;
  border: 0;
}
form textarea {
  width: 100%;
  border: 0;
  border-radius: 25px;
  outline: none;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
form input[type=submit] {
  padding: 15px 35px;
  font-size: 14px;
  font-weight: bold;
  background-color: var(--gray);
  color: var(--white);
  letter-spacing: 0.2em;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  transition: 0.2s;
}
form input[type=submit]:hover, form input[type=submit]:focus {
  background-color: var(--black);
}
form .g-recaptcha {
  display: flex;
  justify-content: center;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
  width: 100%;
  position: relative;
  text-align: center;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots li.slick-active button {
  background: #323232;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
/* Arrows */
.slick-arrow {
  font-family: "Font Awesome 5 Brands";
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 999;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-size: 45px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ed0a01;
}

.slick-prev {
  left: -15px;
}

[dir=rtl] .slick-prev {
  right: -8px;
  left: auto;
}

.slick-prev:before {
  content: "";
  background-image: url(../images/left.png);
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
}

[dir=rtl] .slick-prev:before {
  content: "\f054";
}

.slick-next {
  right: -55px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: -8px;
}

.slick-next:before {
  content: "";
  background-image: url(../images/right.png);
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
}

[dir=rtl] .slick-next:before {
  content: "";
  background-image: url(../images/left.png);
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #969696;
  border-radius: 50%;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: transparent;
}

.admin-form {
  max-width: 500px !important;
}
.admin-form img {
  display: block;
  margin: auto;
  padding: 10px 0;
}
.admin-form .btn-primary {
  background: #f79420 !important;
}
.admin-form .switch-primary > input:checked + label {
  background: #323232 !important;
  border-color: #323232 !important;
}
.admin-form .modal-title {
  color: #323232;
  font: 2.5em "open sans light";
  text-align: center;
}

.help-block {
  background: #ed7764 !important;
  padding: 8px;
  font-family: open sans light;
  text-align: center;
  color: white !important;
  border-left: 6px solid #ce3118;
}

.sidebar-avathar img {
  padding: 15px;
}
.sidebar-avathar h2 {
  font-size: 1.5em;
  text-align: center;
  margin-top: 0px;
}

/*Agignando Variables Globales*/
/*@font-face {
	font-family: 'gothic';
	src: url("../fonts/gothic/gothic.eot");
	src: local('gothic'),
	url("../fonts/gothic/gothic.woff") format('woff'),
	url("../fonts/gothic/gothic.ttf") format('truetype');
}*/
body {
  font-family: "Open Sans" !important;
  font-size: 14px;
  color: #323232;
}

h1, h2, h3, h4, h5 {
  font-family: "Open Sans";
}

a {
  color: #f79420;
}

.active {
  color: #f79420 !important;
}

body.home section.section-banner .banner .slick-slide {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
body.home section.section-banner .banner p {
  position: absolute;
  z-index: 999;
  bottom: 0;
  transform: translate(-50%, -50%);
  left: 50%;
  color: var(--white);
  font-size: 16px;
}
body.home section.section-contact {
  background-color: var(--orange);
  text-align: center;
  padding: 60px;
}
body.home section.section-contact .content-contact {
  max-width: 700px;
  margin: 0 auto;
}
body.home section.section-contact .content-contact h2 {
  font-size: 32px;
  line-height: 32px;
  margin: 0;
  color: var(--gray);
}
body.home section.section-contact .content-contact h3 {
  font-size: 24px;
  margin: 10px 0 0 0;
  color: var(--white);
  padding-bottom: 20px;
}

.current-menu-item a {
  color: #f79420 !important;
}

.active, .current-menu-item a {
  color: #f79420 !important;
}

/*
.revslidemenu, .sliding {
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.revslidemenu{
	left: -200px;
	width: 260px;
	z-index: 1000;
	text-align: left;
	position: fixed;
	top: 0;
	height: 100%;
	.sm-wrap {
		position: absolute;
		top: 0;
		right: 60px;
		background: #323232;
		width: 200px;
		height: 100%;
	}
}


.revslidemenu div.sm-logo {
	font-family: "Open Sans";
	font-size: 32px;
	line-height: 44px;
	color: #fff;
	margin: 20px 10px;
	font-weight: 800;
}

.revslidemenu-close{
	cursor: pointer;
	position: absolute;
	color: #000;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	line-height: 40px;
	top: 36px;
	right: 25px;
	-webkit-transition: all .1s ease-in-out;
	-moz-transition: all .1s ease-in-out;
	-ms-transition: all .1s ease-in-out;
	-o-transition: all .1s ease-in-out;
	transition: all .1s ease-in-out;
}

#sm-trigger, .menu2, .sm-trigger, .revslidemenu a, .revslidemenu-close {
	display: block;
	font-size: 14px;
}

.revslidemenu a {
	margin: 0 0 2px;
	color: #fff;
	letter-spacing: 2px;
	font-weight: 200;
	line-height: 21px;
	text-decoration: none;
	background: rgba(255,255,255,.1);
	padding: 10px;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-ms-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

#sm-trigger, .menu2, .sm-trigger, .revslidemenu a, .revslidemenu-close {
	display: block;
	font-size: 14px;
}

#sm-trigger, .menu2 , .sm-trigger {
	position: absolute;
	top: 20px;
	right: 0;
	z-index: 99999999999999!important;
	text-align: center;
	width: 50px;
	height: 50px;
	border-radius: 50px;
	line-height: 50px;
	cursor: pointer;
	background: url(../images/menu.png) center center rgba(0,0,0,.2);
	transition: all .1s ease-in-out;
}

#sm-trigger, .menu2, .sm-trigger, .revslidemenu-close:hover {
	-webkit-transition: all .1s ease-in-out;
	-moz-transition: all .1s ease-in-out;
	-ms-transition: all .1s ease-in-out;
	-o-transition: all .1s ease-in-out;
	color: #fff;
}

#sm-trigger, .menu2, .sm-trigger, .revslidemenu a, .revslidemenu-close {
	display: block;
	font-size: 14px;
}

.revslidemenu.revslidemenu-open{
	left: 0 !important;
}

.revslidemenu a:hover{
	color: $color_blanco;
	background: $color_mostaza;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-ms-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}
*/
.menu-section {
  background: #323232;
  position: relative;
}

/*fin menu principal*/
.col-center {
  float: inherit;
  margin: auto;
}

.logo-main {
  position: absolute;
  z-index: 499;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.alert-fluid {
  position: absolute;
  z-index: 999;
  width: 100%;
  text-align: center;
  font: 1.5em "open sans light";
}

.promo img {
  margin: auto;
  padding: 5px;
  border: 1px solid #f79420;
}

.projects {
  background: rgba(50, 50, 50, 0.9) url(../images/ver.png) no-repeat center;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.entry:hover .projects {
  opacity: 1;
}

.descripcion {
  float: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.descripcion span.btn {
  border: 1px solid #323232;
  width: 75%;
  border-radius: 0;
  background: none;
  width: 190px;
}
.descripcion span.btn:hover {
  border: 1px solid #f79420;
  color: #f79420;
}
.descripcion .flecha {
  background: url("../images/flecha-right.png");
  width: 18px;
  height: 24px;
  float: inherit;
  margin-top: 8px;
}

.nosotros {
  background: url("../images/bg-nosotros.png") rgba(255, 255, 255, 0.9);
  background-size: cover;
  text-align: center;
  padding: 50px 0;
  border-bottom: 4px solid #323232;
}
.nosotros p {
  font-size: 1.5em;
  margin: auto;
  padding: 20px 0 20px 0;
  width: 80%;
}
.nosotros h2 {
  font: 3em "Open Sans";
}
.nosotros h2 span {
  font-weight: 700;
  color: #f79420 !important;
}
.nosotros h2:after {
  background: #323232;
  content: "";
  display: block;
  height: 1px;
  margin-top: 8px;
  margin: auto;
  width: 150px;
  z-index: 999;
}
.nosotros h1 {
  margin: 0;
  padding: 9px;
  font: 2em "Open Sans Light";
  border-top: 1px solid #f79420;
  border-bottom: 1px solid #f79420;
  font: 2em "Open Sans Light";
}

.services {
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
  padding: 50px 0;
}
.services h2 {
  font: 300 3em "Open Sans";
  padding-bottom: 25px;
}
.services h2 span {
  font-weight: 700;
  color: #f79420 !important;
}
.services .gallery-srv {
  padding-left: 0;
}
.services .gallery-srv .slick-slide img {
  width: auto;
  margin: 0 auto;
}
.services ul h4 {
  font-size: 1.5em;
  color: #323232;
  font-weight: bold;
}
.services ul p {
  font-size: 1.3em;
  line-height: 1.5 !important;
  text-align: justify;
}
.services ul span.btn {
  display: inline-block;
  background: transparent;
  border: 1px solid #f79420;
  color: #f79420;
  font-size: 1em;
  padding: 8px 30px 8px 20px;
  border-radius: 0;
  font-style: italic;
}
.services ul span.btn:hover {
  border: 1px solid #f79420;
  background: #f79420;
  color: #fff;
}
.services ul span.btn i {
  background: url("../images/r.png") no-repeat;
  width: 12px;
  height: 18px;
  position: absolute;
  margin: 0 3px;
}
.services ul span.btn i:hover {
  background: url("../images/r-white.png") no-repeat !important;
}

#portafolio {
  background: url("../images/portafolio.jpg");
  background-size: cover;
  text-align: center;
  border-bottom: 0;
  padding: 50px 0;
  float: left;
  width: 100%;
}
#portafolio h2 {
  font: 300 3em "Open Sans";
  color: #f79420 !important;
}
#portafolio h2:after {
  background: #fff;
  content: "";
  display: block;
  height: 1px;
  margin-top: 8px;
  margin: auto;
  width: 150px;
  z-index: 999;
}
#portafolio article.conta {
  padding: 100px 0;
}
#portafolio article.conta h5 {
  height: 39px;
  display: table;
  font-size: 2em;
  padding: 5px 40px;
  text-decoration: none;
  line-height: 39px;
  margin: 5px;
  border: 1px solid #fff;
  color: #fff;
  margin: auto;
  cursor: pointer;
  -webkit-animation-duration: 8s;
  -webkit-animation-delay: 2s;
  -webkit-animation-iteration-count: infinite;
}
#portafolio article.conta h5 span {
  font-weight: 700;
}

.frase .heading {
  border-bottom: solid 5px #f79420;
}

.modal {
  z-index: 1050;
}
.modal .modal-header {
  background: #f79420;
  color: white;
}
.modal .modal-header .close {
  color: white;
  opacity: 0.8;
}
.modal .modal-body p, .modal .modal-body ul > li {
  font-size: 1.2em;
}
.modal .modal-footer {
  background: #323232;
}

.btn-default {
  color: #323232;
}

.btn-default.focus, .btn-default:focus {
  background: #dadbd9;
  border-color: #f79420;
}

.gothic {
  font-family: "gothic" !important;
}

.carousel-inner > .item > img {
  min-width: 100%;
}

.contenedor {
  border-top: 5px solid #f79420;
}

.btn-general {
  background: #f79420;
  border: 0;
  padding: 8px 25px;
  font: 700 1.5em "Open Sans";
}

.btn-general:hover {
  background: rgba(248, 147, 32, 0.8);
}

.frase span {
  font: 300 1.2em "Open Sans";
}

.pp_pic_holder .ppt a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  -webkit-animation-duration: 3s;
  -webkit-animation-delay: 3s;
  -webkit-animation-iteration-count: infinite;
}

#contactModal .modal-content, #miModal .modal-content, #viModal .modal-content, #vaModal .modal-content,
#designModal .modal-content, #logoModal .modal-content, #multiModal .modal-content, #packModal .modal-content,
#editModal .modal-content, #pubModal .modal-content, #braModal .modal-content, #graModal .modal-content, #imgModal .modal-content,
#appModal .modal-content, #extModal .modal-content {
  background: transparent !important;
  outline: 1px solid #f79420;
  border: 0;
}
#contactModal .modal-header, #miModal .modal-header, #viModal .modal-header, #vaModal .modal-header,
#designModal .modal-header, #logoModal .modal-header, #multiModal .modal-header, #packModal .modal-header,
#editModal .modal-header, #pubModal .modal-header, #braModal .modal-header, #graModal .modal-header, #imgModal .modal-header,
#appModal .modal-header, #extModal .modal-header {
  border: 0;
}
#contactModal .modal-header h4, #miModal .modal-header h4, #viModal .modal-header h4, #vaModal .modal-header h4,
#designModal .modal-header h4, #logoModal .modal-header h4, #multiModal .modal-header h4, #packModal .modal-header h4,
#editModal .modal-header h4, #pubModal .modal-header h4, #braModal .modal-header h4, #graModal .modal-header h4, #imgModal .modal-header h4,
#appModal .modal-header h4, #extModal .modal-header h4 {
  font-weight: 700;
}
#contactModal .modal-body, #miModal .modal-body, #viModal .modal-body, #vaModal .modal-body,
#designModal .modal-body, #logoModal .modal-body, #multiModal .modal-body, #packModal .modal-body,
#editModal .modal-body, #pubModal .modal-body, #braModal .modal-body, #graModal .modal-body, #imgModal .modal-body,
#appModal .modal-body, #extModal .modal-body {
  background: rgba(50, 50, 50, 0.7);
}
#contactModal .modal-body select, #miModal .modal-body select, #viModal .modal-body select, #vaModal .modal-body select,
#designModal .modal-body select, #logoModal .modal-body select, #multiModal .modal-body select, #packModal .modal-body select,
#editModal .modal-body select, #pubModal .modal-body select, #braModal .modal-body select, #graModal .modal-body select, #imgModal .modal-body select,
#appModal .modal-body select, #extModal .modal-body select {
  background: rgba(50, 50, 50, 0.5);
}
#contactModal .panel-footer, #miModal .panel-footer, #viModal .panel-footer, #vaModal .panel-footer,
#designModal .panel-footer, #logoModal .panel-footer, #multiModal .panel-footer, #packModal .panel-footer,
#editModal .panel-footer, #pubModal .panel-footer, #braModal .panel-footer, #graModal .panel-footer, #imgModal .panel-footer,
#appModal .panel-footer, #extModal .panel-footer {
  background: transparent;
  border: none;
}
#contactModal input[type=text], #contactModal input[type=email], #contactModal input[type=tel], #contactModal select, #contactModal textarea, #miModal input[type=text], #miModal input[type=email], #miModal input[type=tel], #miModal select, #miModal textarea, #viModal input[type=text], #viModal input[type=email], #viModal input[type=tel], #viModal select, #viModal textarea, #vaModal input[type=text], #vaModal input[type=email], #vaModal input[type=tel], #vaModal select, #vaModal textarea,
#designModal input[type=text],
#designModal input[type=email],
#designModal input[type=tel],
#designModal select,
#designModal textarea, #logoModal input[type=text], #logoModal input[type=email], #logoModal input[type=tel], #logoModal select, #logoModal textarea, #multiModal input[type=text], #multiModal input[type=email], #multiModal input[type=tel], #multiModal select, #multiModal textarea, #packModal input[type=text], #packModal input[type=email], #packModal input[type=tel], #packModal select, #packModal textarea,
#editModal input[type=text],
#editModal input[type=email],
#editModal input[type=tel],
#editModal select,
#editModal textarea, #pubModal input[type=text], #pubModal input[type=email], #pubModal input[type=tel], #pubModal select, #pubModal textarea, #braModal input[type=text], #braModal input[type=email], #braModal input[type=tel], #braModal select, #braModal textarea, #graModal input[type=text], #graModal input[type=email], #graModal input[type=tel], #graModal select, #graModal textarea, #imgModal input[type=text], #imgModal input[type=email], #imgModal input[type=tel], #imgModal select, #imgModal textarea,
#appModal input[type=text],
#appModal input[type=email],
#appModal input[type=tel],
#appModal select,
#appModal textarea, #extModal input[type=text], #extModal input[type=email], #extModal input[type=tel], #extModal select, #extModal textarea {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 0;
  color: #fff;
}
#contactModal label, #miModal label, #viModal label, #vaModal label,
#designModal label, #logoModal label, #multiModal label, #packModal label,
#editModal label, #pubModal label, #braModal label, #graModal label, #imgModal label,
#appModal label, #extModal label {
  color: #fff;
}
#contactModal p, #contactModal ul > li, #miModal p, #miModal ul > li, #viModal p, #viModal ul > li, #vaModal p, #vaModal ul > li,
#designModal p,
#designModal ul > li, #logoModal p, #logoModal ul > li, #multiModal p, #multiModal ul > li, #packModal p, #packModal ul > li,
#editModal p,
#editModal ul > li, #pubModal p, #pubModal ul > li, #braModal p, #braModal ul > li, #graModal p, #graModal ul > li, #imgModal p, #imgModal ul > li,
#appModal p,
#appModal ul > li, #extModal p, #extModal ul > li {
  color: #fff;
  font-weight: 300;
}
#contactModal ul > li, #miModal ul > li, #viModal ul > li, #vaModal ul > li,
#designModal ul > li, #logoModal ul > li, #multiModal ul > li, #packModal ul > li,
#editModal ul > li, #pubModal ul > li, #braModal ul > li, #graModal ul > li, #imgModal ul > li,
#appModal ul > li, #extModal ul > li {
  list-style-image: url(../images/r.png);
  line-height: 2;
  vertical-align: top;
}

.promocion {
  right: -128px;
  width: 270px;
  height: 270px;
  position: fixed;
  right: -128px;
  top: 225px;
  z-index: 300;
  background-image: url("../images/promociones/promo-semanal.png");
}
.promocion a {
  width: 270px;
  height: 100%;
  position: relative;
  margin: 0 0 0 100px;
  text-indent: -9999em;
  display: block;
}

footer {
  background-image: url("../images/bg-footer.jpg");
  background-color: var(--gray);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 0 10px;
  font-size: 14px;
  border-top: 4px solid #323232;
  float: left;
  width: 100%;
  text-align: center;
  border-top: 1px solid var(--orange);
}
footer .pie {
  margin: 25px 0;
}
footer ul {
  padding-left: 0;
}
footer ul li {
  display: inline-block;
  margin: 5px;
}
footer ul li > a:hover > img {
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -ms-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
  opacity: 0.2;
}
footer ul li.li-wa {
  display: none;
}
footer a {
  color: #cbcbcb;
  text-decoration: none;
}
footer a:hover {
  color: var(--white);
}
footer a svg {
  font-size: 42px !important;
}
footer p {
  padding: 0;
  margin: 0;
  color: #cbcbcb !important;
}

.alert {
  z-index: 1000;
  position: fixed;
  width: 80%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.alert ul li {
  list-style-type: none;
}

@media (max-width: 1200px) {
  body.home section.section-banner .banner img {
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }
  body .container, body header .main_menu {
    max-width: 960px;
  }
}
@media (max-width: 1024px) {
  body .container, body header .main_menu {
    max-width: 720px;
  }
}
@media (max-width: 736px) {
  body.home section.section-contact {
    padding: 25px 15px;
  }
  body .container, body header .main_menu {
    max-width: 400px;
  }
}
@media (max-width: 480px) {
  header .main_menu .container_logo a {
    padding-left: 15px;
  }
  .nosotros h2 {
    font-size: 2em;
  }
  .nosotros p {
    font-size: 1.2em;
  }
  .logotipo img {
    width: 85%;
  }
  .sliders .alert-warning {
    margin-top: 0 !important;
  }
  .services ul p {
    font-size: 1.2em !important;
    width: 90%;
  }
  .portafolio a.thumbnail img {
    width: 100%;
  }
  .portafolio article h5 {
    font-size: 1.5em !important;
  }
  .descripcion span.btn {
    width: 100% !important;
    margin: 2px 0;
  }
  div.primary ul li a {
    margin: auto;
    margin-top: 2px;
  }
  .promocion {
    top: inherit !important;
    bottom: 0;
  }
  div.light_square .pp_close {
    top: -4px !important;
    right: -29px !important;
  }
  div.light_square .pp_description {
    margin-right: 0 !important;
  }
  .logo-main img {
    width: 100% !important;
  }
  .camera_caption > div {
    font-size: 1.5em !important;
    margin-top: -5px !important;
  }
  .menu-section .fluid_container {
    margin-bottom: 0 !important;
    height: initial !important;
  }
  #inicio .container {
    padding: 0 !important;
  }
  body.home section.section-banner .banner p {
    font-size: 12px;
    width: 100%;
    bottom: 20px;
    text-align: center;
  }
  body.home section.section-contact .content-contact h2 {
    font-size: 2em;
  }
  body.home section.section-contact .content-contact h3 {
    font-size: 20px;
  }
  body.home section.section-contact .content-contact input[type=text],
  body.home section.section-contact .content-contact input[type=tel],
  body.home section.section-contact .content-contact input[type=email] {
    height: 40px;
    padding: 5px 20px;
    font-size: 14px;
  }
  body.home section.section-contact .content-contact textarea {
    padding: 5px 20px;
    font-size: 14px;
  }
  body.home section.section-contact .content-contact input[type=submit] {
    width: 100%;
  }
  body.home section.services .slick-dots {
    margin-top: 15px;
  }
  footer ul li.li-wa {
    display: inline-block;
  }
}
@media (min-width: 481px) {
  .services h2 {
    font-size: 3.5em !important;
  }
  #portafolio h2 {
    font-size: 3.5em !important;
  }
}